import React from "react";

const Testament = () => {
  return (
    <div>
      <h3 style={{ textAlign: "center", fontFamily: "Lobster", fontSize: 36, marginTop: 50 }}>Testament</h3>

      <div className="review-img">
        <img src={require("../assets/images/reviews.png")} className="testament-img" />
      </div>
    </div>
  );
};

export default Testament;
