export const experiences = [
  {
    id: 41,
    title: "Fundrate.io",
    type: "javascript",
    subTitle: "Collecting crowdfunding data from across the web.",
    technologies: ["reactlogo", "tailwind", "nextjslogo", "pythonlogo", "awsec2logo", "awsiamlogo", "awss3logo", "awsrdslogo",  "linuxlogo"],
    description: ["Create frontend and backend application using nextjs and tailwind.", "Create an autonomous scraper in python using selenium that scrape croudfunding campaings on a regular basis." , "Host the scraper on an EC2 and connect to a pipeline to streamline publications.", "Create a bash script to initiates the python script.", "Download images and videos from the website, store the assets on S3 and create a reference inside a AWS RDS database.", "Host nextjs project on vercel with an development and production environment", "Set up email service using mailgun for the nextjs project and for the python scrape.", "Add failsafe code to continue scrape and notify developer in case of errors."],
    imgSrc: ["fundrate1", "fundrate2", "fundrate3"],
    liveLink: "https://fundrate.io",
  },
  {
    id: 35,
    title: "Media Mob",
    type: "javascript",
    subTitle: "Fullstack Developer",
    technologies: ["reactlogo", "reduxlogo", "awsdynamodblogo", "awsiamlogo", "awscloudfrontlogo", "awsec2logo", "awsrdslogo"],
    description: ["Integrated load banlancer using nginx.", "Added Strapi backend to ec2 server.", "Connected domain name to backend and frontend of application using load balancer, route53 and cloudfront.", "Added messaging service.", "Added ability to change between different roles.", "Included aws RDS table to store data in postgres"],
    imgSrc: ["mediamob1", "mediamob2", "mediamob3"],
    liveLink: "https://mediamob.io",
  },
  {
    id: 37,
    title: "Bodyweight Pro Website + Apps",
    type: "javascript",
    subTitle: "Fullstack Web + Mobile Developer",
    technologies: ["reactlogo", "typescriptlogo", "firebase", "reactnativelogo", "nodejslogo", "postgreslogo"],
    description: ["Create the website using reactjs and typescript", "Create the apps for IOS and Android using react-native", "The graphics were developed using adobe illustrator and figma", "A nodejs server is hosted on google cloud connected to a postgres database.", "The apps are both protected by a firebase authentication layer."],
    imgSrc: ["bodyweightproweb"],
    liveLink: "https://bodyweightpro.com",
  },
  {
    id: 39,
    title: "NPM package - react-input-button",
    type: "javascript",
    subTitle: "Frontend Web",
    technologies: ["reactlogo", "typescriptlogo", "nodejslogo", "htmllogo", "csslogo"],
    description: ["Create a npm package in reactjs and typescript.", "The package allows user to use a custom input file upload button instead of the default file upload button.", "There are multiple ways to change the properties and add a components instead of using a button."],
    imgSrc: ["reactinputbutton"],
    liveLink: "https://www.npmjs.com/package/react-input-button",
  },
  {
    id: 36,
    title: "Public Gem",
    type: "javascript",
    subTitle: "Fullstack Developer",
    technologies: ["nextjslogo", "postgreslogo", "awss3logo", "awslambdalogo"],
    description: ["Used nextjs to create  website to host trips.", "Used postgres to manage data", "AWS s3 was used for storing images", "Set rules to only allow authenticated users to save images", "Create separate dashboard for trip providers and patrons."],
    imgSrc: ["publicgem1", "publicgem2", "publicgem3", "publicgem4"],
  },
  {
    id: 40,
    title: "ElCharro",
    type: "wordpress",
    subTitle: "Mexican Food Restaurant Chain",
    technologies: ["wordpresslogo", "phplogo", "javascriptlogo", "htmllogo", "csslogo", "illustrator", "photoshop"],
    description: ["Customized plugins using javascript and php to create a popup that allowed users to book the food truck.", "Added SEO optimizations to improve search engine ranking such as optimizing keywords on the site and adding relevant meta data.", "Changed the theme to add a new visual editor.", "Added an external API services to book food using doordash."],
    imgSrc: ["elcharro"],
    liveLink: "https://elcharrousa.com",
  },
  
  {
    id: 26,
    title: "Space X Website",
    type: "python",
    subTitle: "Python Website that uses flask to demonstrate SpaceX data",
    technologies: ["pythonlogo", "flasklogo", "htmllogo", "heroku"],
    description: ["A api service was built using flask, which fetched data from the spacex server.", "The website is hosted on heroku.", "The website automatically updates the content based on the data fetched from the api."],
    imgSrc: ["spacex"],
    liveLink: "https://dashboard.heroku.com/apps/pythonweb33",
    githubLink: "https://github.com/kanyo33/flask-website",
  },
  {
    id: 30,
    title: "Bodyweight Pro",
    type: "mobile",
    subTitle: "Gamefied Fitness App",
    technologies: ["reactnativelogo", "firebase", "awsec2logo", "css", "html", "nodejs", "illustrator"],
    description: ["Designed and developed the app for both ios and android using react native.", "Added elements from gamification to improve user interaction", "Users can create custom workouts.", "Weekly competitions are held for who has the most amount of points.", "Created all logos and assets, including badges and banners.", "Connected to a Google cloud server to keep track of points and trophies obtained"],
    imgSrc: ["bodyweightpro1", "bodyweightpro2", "bodyweightpro3", "bodyweightpro4", "bodyweightpro5", "bodyweightpro6", "bodyweightpro7", "bodyweightpro8", "bodyweightpro9"],
    liveLink: "https://play.google.com/store/apps/details?id=com.bodyweightfitness&hl=en&gl=US"
  },
  {
    id: 31,
    title: "Corona App",
    type: "mobile",
    subTitle: "Fullstack Developer",
    technologies: ["reactnativelogo", "firebase"],
    description: ["The app tracks the user walking radius for the day and send all users a notification when a person who entered the radius during a specific day or range of dates.", "Added custom permissions to user authentication.", "News about the corona virus are shown in the user language.", "The map chunks all data points to avoid rendering all 1million+ data points at once causing the app to crash."],
    imgSrc: ["corona2", "corona3", "corona4", "corona5", "corona6", "corona7", "corona8", "corona9", "corona13", "corona19"],
    github: "https://github.com/kanyo33/CoronaApp",
  },
  {
    id: 32,
    title: "Huntstand",
    type: "backend",
    subTitle: "Backend Developer",
    technologies: ["awsamplifylogo", "awsiamlogo", "awslambdalogo", "awsdynamodblogo", "awscognito"],
    description: ["Created the backend using amplify.", "Managed access to different users within the team.", "Connected Amplify to dynamodb and custom lambda functions", "Connected amplify to retool to connect a GUI to the backend data flow.", "Connected backend to retool.", "Create custom lambda functions to convert data in csv and store a copy in S3"],
    imgSrc: ["huntstand"],
    liveLink: "https://www.huntstand.com/",
  },
  {
    id: 33,
    title: "Corona Better World",
    type: "javascript",
    subTitle: "Fullstack Developer",
    technologies: ["awss3logo", "reactlogo", "awscloudfrontlogo", "csslogo", "awsec2logo"],
    description: ["Developer the website", "Set up hosting on S3", "Connect route51 to cloudfront to load balance.", "Build frontend using reactjs", "Connect form using custom emailing service", "Host website on s3 and deploy with aws cloudfront.", "Deployed the website on and Ec2"],
    imgSrc: ["protectparadise", "protectparadise2", "protectparadise3", "protectparadise4"],
    liveLink: "https://www.coronaextra.ca/ca_en",
  },
  {
    id: 34,
    title: "Gamechangerz",
    type: "backend",
    subTitle: "Backend Developer",
    technologies: ["reactlogo", "awsamplifylogo", "awscloudfrontlogo"],
    description: ["Added cloudfront to existing amplify backend", "Bug fixed existing hosting issues.", "Improved navigation on the website."],
    imgSrc: ["gamechangerz1", "gamechangerz2", "gamechangerz3", "gamechangerz4"],
    liveLink: "https://www.gamechangerz.ca",
  },
  {
    id: 1,
    title: "JamMedia",
    type: "wordpress",
    subTitle: "Social Media Company",
    technologies: ["wordpresslogo", "htmllogo", "csslogo", "illustrator", "photoshop"],
    description: ["Create the website from scratch", "Connected a contact form and subscription box to improve client communication.", "Added navigation and researched assets for general layout."],
    imgSrc: ["jamdetroit"],
    liveLink: "https://jamdetroit.com",
  },
  {
    id: 2,
    title: "Vault Pipelines",
    type: "wordpress",
    subTitle: "Albertan Pipeline Company",
    technologies: ["wordpresslogo", "htmllogo", "csslogo", "illustrator", "photoshop"],
    description: ["Created the design using indesign.", "Added graphics and customized email plugin to send out automatic email responses.", "Created responsive banners to encourage click through rate.", "Uses layerbuilder to design 3d banners."],
    imgSrc: ["vault"],
    liveLink: "http://vaultpipelines.com/",
  },
  {
    id: 3,
    title: "Forexviser",
    type: "wordpress",
    subTitle: "AI Powered Forex Software",
    technologies: ["wordpresslogo", "phplogo", "javascriptlogo", "htmllogo", "csslogo", "illustrator", "photoshop", "dimensions"],
    description: ["Customized sales plugins using PHP.", "Implemented a messenger bot to talk to clients directly though the website using facebook messenger.", "Used an upsell plugin to encourage people to buy the software.", "Distributed the key for the api via a custom api to ensure only the designated user can use the software."],
    imgSrc: ["forexvisor"],
    liveLink: "https://forexviser.com/",
  },
  {
    id: 25,
    title: "Python Map",
    type: "python",
    subTitle: "Interactive Map to show mountains and population",
    technologies: ["pythonlogo", "flasklogo", "htmllogo"],
    description: ["Created an interactive map that displays volcanoes in California and the population around the globe by using panda.", "Panda was used to iterate through the excel and text files to visually display data.", "An option was added to remove certain values on the map and only show relevant data."],
    imgSrc: ["mappython"],
    githubLink: "https://github.com/kanyo33/WebMap",
  },

  {
    id: 27,
    title: "Note Taking Api",
    type: "python",
    subTitle: "Python note taking app with react and django",
    technologies: ["pythonlogo", "djangologo", "reactlogo"],
    description: ["Implemented a django backend to store the notes on an sql lite database.", "Created the UI with react hooks.", "New notes can be deleted and added on the django admin panel"],
    imgSrc: ["djangoreact"],
    githubLink: "https://github.com/kanyo33/django-react-noteapp",
  },
  {
    id: 28,
    title: "Django Portfolio Page",
    type: "python",
    subTitle: "A portfolio page using Django with Django Templates",
    technologies: ["pythonlogo", "djangologo", "postgreslogo", "bootstraplogo"],
    description: ["Added a postgres server to store projects.", "All projects are added through the adjango admin panel", "Added Bootstrap for quick design implementation."],
    imgSrc: ["djangoportfolio"],
    githubLink: "https://github.com/kanyo33/django-portfolio",
  },
  {
    id: 29,
    title: "Django Product Hunter",
    type: "python",
    subTitle: "Django Authentication App with ability to upvote",
    technologies: ["pythonlogo", "djangologo", "postgreslogo", "bootstraplogo"],
    description: ["Added a postgres server to store products found.", "Uses Django authentication methods to allow users to login and sign up", "People can post products found on the frontend."],
    imgSrc: ["producthunt"],
    githubLink: "https://github.com/kanyo33/django-producthunt",
  },
  {
    id: 4,
    title: "Wunderinotattos",
    type: "wordpress",
    subTitle: "German Tattoo Site",
    technologies: ["wordpresslogo", "htmllogo", "csslogo", "illustrator", "photoshop"],
    description: ["Created the entire site using Elementor.", "Researched additional images, wrote the content and made it compatible for all devices.", "Built the site in under three days."],
    imgSrc: ["wunder"],
    liveLink: "https://wunderinotattos.com/",
  },
  {
    id: 5,
    title: "Freedom Hill Banquet",
    type: "wordpress",
    subTitle: "Event Catering Company",
    technologies: ["wordpresslogo", "htmllogo", "csslogo", "illustrator", "photoshop"],
    description: ["Implemented SEO strategies and added a google analytics dashboard.", "Configured the google analytics dashboard to allow user to track relevant data.", "Performed keyword searches to optimize content on the site.", "Used back-linking strategies to increase search engine ranking for specific categories."],
    imgSrc: ["freedom"],
    liveLink: "http://freedomhillbanquet.com/",
  },
  {
    id: 6,
    title: "Macomb Feed The Need",
    type: "wordpress",
    subTitle: "Non-Profit Helping the Homeless",
    technologies: ["wordpresslogo", "javascriptlogo", "htmllogo", "csslogo", "illustrator", "photoshop"],
    description: ["Improved the layout of the site.", "Added a news section and routinely updated the content.", "Added an option to donate with paypal.", "Added a spam filter to the contact form."],
    imgSrc: ["macomb"],
    liveLink: "http://www.macombftn.org/",
  },
  {
    id: 7,
    title: "Steel House Tavern",
    type: "wix",
    subTitle: "Detroit Restaurant",
    technologies: ["wixlogo"],
    description: ["Improved the look of the menu.", "Fixed email issues regarding the client receiving no emails.", "Changed the navigation.", "Added social media buttons."],
    imgSrc: ["steel"],
    liveLink: "http://www.steelhousetroy.com/",
  },
  {
    id: 41,
    title: "Book Cover",
    type: "graphics",
    subTitle: "Fitness Book Print Cover",
    technologies: ["photoshop", "illustrator", "indesign"],
    description: ["Created a Amazon print cover for a fitness book.", "Added text in different fonts, removed background from images, made sure it matched the dimensions based on page number of books, width and height.", "The book cover was optimized for the ebook.", "The images were edited using photoshop, while the main design was created using illustrator."],
    imgSrc: ["bookcover"],
    liveLink: "https://www.amazon.de/Bodyweight-Workout-Manual-Convenient-Solution/dp/199955602X/ref=sr_1_13?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=workout+manual&qid=1571425119&sr=8-13",
  },
  {
    id: 8,
    title: "Fitness Flyer",
    type: "graphics",
    subTitle: "Flyer for a fitness study",
    technologies: ["photoshop", "illustrator"],
    description: ["Created a fitness flyer for a study.", "The flyer was mainly made using Illustrator and the background of the images was removed using photoshop.", "Copies were made of the flyer and hung around campus to attract students to a study."],
    imgSrc: ["fitnessflyer"],
  },
  {
    id: 9,
    title: "3D Laptop",
    type: "graphics",
    subTitle: "Profits shown on a Laptop",
    technologies: ["photoshop", "illustrator", "dimensions"],
    description: ["Created the 3d graphic using dimensions for the 3d effect.", "The 2d graphics were created using illustrator and photoshop.", "Lights and angles were adjusted to match the purpose on the website."],
    imgSrc: ["laptop"],
    liveLink: "https://forexviser.com",
  },
  {
    id: 10,
    title: "Logo Design",
    type: "graphics",
    subTitle: "Forex Logo Design",
    technologies: ["photoshop", "illustrator"],
    description: ["Created a Logo that was used on a variety of other graphics.", "The logo was mainly created using illustrator.", "The logo contains different sizes for different screen sizes.", "There is a png version to use as a label and a jpg version for the website."],
    imgSrc: ["logodesign"],
    liveLink: "https://forexviser.com",
  },
  {
    id: 11,
    title: "Social Media Banner",
    type: "graphics",
    subTitle: "Beam Deal Social Media Banner",
    technologies: ["photoshop", "illustrator"],
    description: ["Created the logo and the banner to using illustrator and Photoshop.", "The dimensions match the requirements listed by instagram, facebook and twitter.", "Transparency was added to the assets to combine them on a single graphic."],
    imgSrc: ["socialmedia"],
  },
  {
    id: 12,
    title: "3D Software Graphic",
    type: "graphics",
    subTitle: "Forex Software Package",
    technologies: ["photoshop", "illustrator", "dimensions"],
    description: ["Created the outline of the package using dimensions.", "The 2d graphics where created using photoshop and illustrator.", "The light and angle of the design match the purpose of the website."],
    imgSrc: ["software"],
    liveLink: "https://forexviser.com",
  },
  {
    id: 13,
    title: "Hypnotherapy Web Application",
    type: "javascript",
    subTitle: "Inserting Features and Fixing Bugs",
    technologies: ["javascriptlogo", "htmllogo", "nodejslogo", "csslogo", "restapilogo"],
    graphics: false,
    description: ["Fixed bugs that caused certain buttons to disappear and negatively affected the ability for clients to schedule events.", "Blocked certain assets from firebase to allow client to unlock them later.", "Used EJS, Jquery, Vanilla Javascript and firebase to improve the app."],
    imgSrc: ["feature1"],
    liveLink: "http://applicationdusucces.com/home",
  },
  {
    id: 14,
    title: "Random Joke Generator",
    type: "javascript",
    subTitle: "Application to fetch different Jokes",
    technologies: ["javascriptlogo", "htmllogo", "nodejslogo", "csslogo", "restapilogo"],
    graphics: false,
    description: ["Creating a random joke generator to fetch jokes from different Api backends.", "Nodejs was used as the backend to fetch jokes, while react was used to display the jokes", "The app was loaded onto a glitch account to view it live."],
    imgSrc: ["randomjoke"],
    liveLink: "https://github.com/kanyo33/randomjokegenerater",
    githubLink: "https://kanyo33.github.io/randomjokegenerater/",
  },
  {
    id: 15,
    title: "React Form",
    type: "javascript",
    subTitle: "React Form with State Management Display",
    technologies: ["javascriptlogo", "htmllogo", "reactlogo", "nodejslogo", "csslogo", "bootstraplogo"],
    graphics: false,
    description: ["Created a form using ReactJS that includes a state management indicator and form validation.", "It uses Bootstrap to speed up the designing and focus on the features.", "The form also come with basic validation.", "The data can be saved on the front end to check the state."],
    imgSrc: ["reactform"],
    liveLink: "https://kanyo33.github.io/reactformeg/",
    githubLink: "https://github.com/kanyo33/reactformeg",
  },
  {
    id: 16,
    title: "Dom Creator",
    type: "javascript",
    subTitle: "Ability to create elements within the DOM",
    technologies: ["javascriptlogo", "htmllogo", "csslogo"],
    graphics: false,
    description: ["Provides the ability to create elements directly on the dom and save them to local storage.", "The app uses vanilla js, html and css.", "Different selectors, colors and animations can be used to create a basic dom layout."],
    imgSrc: ["domcreator"],
    liveLink: "https://kanyo33.github.io/DomElementCreator/",
    githubLink: "https://github.com/kanyo33/DomElementCreator",
  },
  {
    id: 17,
    title: "Exercise Tracker",
    type: "javascript",
    subTitle: "Saving Data To MongoDB",
    technologies: ["javascriptlogo", "htmllogo", "reactlogo", "nodejslogo", "csslogo", "restapilogo", "mongodblogo"],
    graphics: false,
    description: ["Created a an application that tracks user information.", "The data is stored as a JSON string on MongoDB.", "It returns the string after submission to the client.", "It uses a simple rest api to send data back and forward between the front and backend."],
    imgSrc: ["exercisetracker"],
    liveLink: "https://kanyo33-exercisetracker.glitch.me/",
    githubLink: "https://github.com/kanyo33/exerciseTracker",
  },
  {
    id: 18,
    title: "React Calculator",
    type: "javascript",
    subTitle: "A React Hooks Calculator",
    technologies: ["javascriptlogo", "htmllogo", "reactlogo", "nodejslogo", "csslogo"],
    graphics: false,
    description: ["Created a calculator using react hooks with internal state management using the contextApi.", "The calculator stores values on the state to continue typing values into the calculator for further calculations.", "The front end was written in reactjs to build and easy to read component structure of the features."],
    imgSrc: ["reactcalc"],
    liveLink: "https://kanyo33.github.io/reactHookCalc/",
    githubLink: "https://github.com/kanyo33/reactHookCalc",
  },
  {
    id: 19,
    title: "Url Shortener",
    type: "javascript",
    subTitle: "Application to Shorten Domain Names",
    technologies: ["javascriptlogo", "htmllogo", "reactlogo", "nodejslogo", "csslogo", "restapilogo", "mongodblogo"],
    graphics: false,
    description: ["Created an application that shortens the url and stores the result on mongoDB.", "The url once entered returns a shortened version that can be reused by the client and stored.", "Mongodb atlas was used to simplify the backend development process."],
    imgSrc: ["domshortener"],
    liveLink: "https://kanyo33-urlshortener-2.glitch.me/",
    githubLink: "https://github.com/kanyo33/urlShortener/",
  },
  {
    id: 20,
    title: "Fitness App",
    type: "mobile",
    subTitle: "Premium Subscription Fitness App",
    technologies: ["reactnativelogo", "firebaselogo", "reduxlogo", "sasslogo"],
    description: ["Created a fitness app that counts calories, shows videos and contain an interactive svg muscle map.", "Implemented features to track calories, time, workouts finished and Much More.", "Added a stripe feature to sign up for the premium version to remove Ads and gain extra access to certain features.", "Premium accounts are stored on firebase."],
    imgSrc: ["summaryscreenshots", "fitvise1", "fitvise2", "fitvise3", "fitvise4", "fitvise5", "fitvise6", "fitvise8", "fitvise10, fitvise11", "fitvise12"],
    liveLink: "https://play.google.com/store/apps/details?id=bodyweight.workouts33",
  },
  {
    id: 21,
    title: "Business Card App",
    type: "mobile",
    subTitle: "Simple Business Card App",
    technologies: ["flutterlogo", "dartlogo", "androidstudio"],
    description: ["Simple App to add business card to the app.", "Used android studio to build the app.", "Contains a simple widget structure to quickly make changes and add new features."],
    imgSrc: ["introapp"],
    githubLink: "https://github.com/kanyo33/introApp",
  },
  {
    id: 22,
    title: "Play Xylophone",
    type: "mobile",
    subTitle: "Play your tunes on an instrument",
    technologies: ["flutterlogo", "dartlogo", "androidstudio"],
    description: ["An App that plays different sounds when the button is clicked.", "The app uses Listview to render a list of different buttons.", "The sounds can be played on top of each other to create new sounds.", "Mp4 files were used and attached programmatically to each button."],
    imgSrc: ["xylophone"],
    githubLink: "https://github.com/kanyo33/playSounds",
  },
  {
    id: 23,
    title: "To Do App",
    type: "mobile",
    subTitle: "Storing information on the phone.",
    technologies: ["flutterlogo", "dartlogo", "androidstudio"],
    description: ["An app to stores different to do items on the phone.", "Uses CRUD cycle to create, read, update and delete items using flutter.", "Uses state management to keep track of values added and deleted.", "Uses floating button to add new components and has a checkbox to easy remove items."],
    imgSrc: ["todo"],
    githubLink: "https://github.com/kanyo33/flutterSavingData",
  },
  {
    id: 24,
    title: "Dictionairy Look Up",
    type: "python",
    subTitle: "Searches Definitions",
    technologies: ["pythonlogo"],
    description: ["Created an app that searches words in a large list of Json file.", "If the word is not found it searches the words closest related to the word or nothing", "The python app uses helper libraries to quickly search for similar words."],
    imgSrc: ["dictionairypython"],
    githubLink: "https://github.com/kanyo33/dictionaryPython",
  },
];
