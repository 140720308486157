import React from "react";
import "./experience.css";
import { resume } from "../../resume";
import { ChevronRight } from 'lucide-react'

const experience = () => {
  return (
    <div>
      <h3 className="bio-title" style={{ textAlign: "center", fontFamily: "Lobster" }}>
        Experience
      </h3>
      <hr />
      {resume.map((a, i) => (
        <div key={a.id} style={{ marginBottom: 30 }}>
          <span className="exp-title">{a.title}</span>
          <br />
          <span className="exp-month">{a.date}</span>
          {a.desc.map((b, j) => (
            <div key={j} className="flex text-[16px]" >
              <span className="min-w-[20px] mt-1"><ChevronRight size={18} className="text-gray-400" /></span>
                <span>{b}</span>
            </div>
            
          ))}
        </div>
      ))}
    </div>
  );
};

export default experience;
