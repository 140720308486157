import React from "react";

const Biography = () => {
  return (
    <div>
      <h3 className="bio-title" style={{ fontFamily: "Lobster", textAlign: "center" }}>
        Biography
      </h3>
      <hr />
      <div>
        <p style={{ fontSize: 22, fontWeight: "bold", fontFamily: "Lobster" }}>Developer Story</p>
        <p style={{ fontSize: 15, marginBottom: 30 }}>
          I'm a <b>full stack software engineer with over 8 years of experience</b>. I have helped more than a dozen clients realize their projects by building websites, web apps and mobile apps. My journey began by designing websites using Wordpress 5 years ago and gradually evolved into building custom websites using Javascript and Python. As a web and mobile developer and graphic designer, i learned to managed all aspects of creating and appealing and fully functional product my clients enjoyed.
        </p>
        <p style={{ fontSize: 22, fontWeight: "bold", fontFamily: "Lobster" }}>Designer Story</p>
        <p style={{ fontSize: 15, marginBottom: 30 }}>
          I also have <b>8 years of experience as a graphic designer</b>. Creating graphics was my first passion, before i dove deeper into web design. So far I have created logos, websites, UI designs for Apps, brochures, book covers and social media banners. After developing a good grasp of Photoshop and Illustrator, designing different products became natural. While I enjoy designing graphics using different Softwares, I scetch everything out on paper. Seeing the designs on paper helps me create a natural design that connects the related elements or pages.
        </p>
        <p style={{ fontSize: 22, fontWeight: "bold", fontFamily: "Lobster" }}>Personal Story</p>
        <p style={{ fontSize: 15, marginBottom: 30 }}>
          I'm <b>constantly learning about new technologies </b> and am currently gaining more experience with React, Express JS, Node JS, Python and PHP. The goal is to work with a variety of clients that require enterprise grade software and assist them with all other related needs. I'd like to become the one-stop solutions to a clients needs by offering the web, mobile and graphic related services they need to be successful.
        </p>

        <p style={{ fontSize: 15, marginBottom: 30 }}>When I'm not learning something new, I read books, learn Hungarian and spend time with friends. Recently I finished reading "Hooked" by Nir Eyal, which was an eye opener to how software applications can influence human emotions. Anyone who is interested in learning more about how social media influences user should buy a copy.</p>
      </div>
    </div>
  );
};

export default Biography;
