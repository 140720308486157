import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./App.css";
import "./pages/page.css";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Projects from "./pages/Projects";
import SimpleReactLightbox from "simple-react-lightbox";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Noah Kanyo",
      headerLinks: [
        { title: "Home", path: "/" },
        { title: "About", path: "/about" },
        { title: "Contact", path: "/contact" },
        { title: "Projects", path: "/projects" },
      ],
      home: {
        title: "Let's Bring Your Project's To Life.",
        subTitle: "Fulfilling Design and Development needs.",
        text: "Scroll through the projects below",
      },
      about: {
        title: "About Me",
      },
      contact: {
        title: "Let's work together",
      },
    };
  }

  render() {
    return (
      <SimpleReactLightbox>
        <div className="nav-color" />
        <Router>
          <Container className="p-0" fluid={true}>
            <Navbar className="border-bottom" bg="transparent" expand="lg">
              <img src={require("./assets/images/profileimage.jpg")} style={{ height: 50, width: 50, borderRadius: 30, marginRight: 20 }} />
              <Navbar.Brand style={{ fontWeight: "bold", fontFamily: "Lobster" }}>Noah Kanyo</Navbar.Brand>

              <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
              <Navbar.Collapse id="navbar-toggle">
                <Nav className="ml-auto">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                  <Link className="nav-link" to="/projects">
                    Projects
                  </Link>
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Routes>
              <Route path="/" exact element={<HomePage title={this.state.home.title} subTitle={this.state.home.subTitle} text={this.state.home.text} />} />
              <Route path="/about" element={<AboutPage title={this.state.about.title} />} />
              <Route path="/contact" element={<ContactPage title={this.state.contact.title} />} />
              <Route path="/projects" element={<Projects title={this.state.contact.title} />} />
            </Routes>
            <Footer />
          </Container>
        </Router>
      </SimpleReactLightbox>
    );
  }
}

export default App;
