import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./featured.css";
import { useNavigate } from "react-router-dom";
import ProjectComp from "./ProjectComp";
import { Layout, TabletSmartphone, LayoutGrid, MoreHorizontal } from 'lucide-react'

const btns = [
  {
    id: 41,
    title: "Web App",
    value: "webapp",
    icon: <LayoutGrid className="text-white" />
  },
  {
    id: 30,
    title: "Mobile App",
    value: "mobileapp",
    icon: <TabletSmartphone className="text-white" />
  },
  {
    id: 2,
    title: "Website Created",
    value: "website",
    icon: <Layout className="text-white" />
  },
  {
    id: 4,
    title: "More Projects",
    icon: <MoreHorizontal className="text-white" />
  },
];

const Featured = (props) => {
  const navigate = useNavigate();
  const { experiences } = props;
  const [topic, setTopic] = useState(btns[0]);
  const [display, setDisplay] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (topic?.value) {
      const getExperience = experiences.filter((a) => a.id === topic.id);
      setDisplay(getExperience);
      setLoading(false);
    } else {
      setTopic(btns[0]);
    }
  }, [topic]);

  const topicChange = (topic) => {
    if (topic.value) {
      setTopic(topic);
    } else {
      navigate("/projects");
    }
  };

  return (
    <Container fluid={true}>
      <div className="w-full">
        <p style={{ marginTop: 30, textAlign: "center", fontFamily: "Lobster", fontSize: 36 }}>Featured Work</p>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
          {btns.map((a, i) => (
            <button key={i} onClick={() => topicChange(a)} className={topic.value === a.value ? "featured-btn-active lg:text-2xl sm:text-lg relative" : "featured-btn lg:text-2xl sm:textlg relative"} style={{ width: "25%", minHeight: 110 }}>
              {a.title}
              <div className="absolute z-10 top-1 left-1">
                {a.icon}
              </div>
            </button>
          ))}
        </div>
        <div className="flex w-full justify-center items-center">
        {!loading && <ProjectComp display={display[0]} />}
        </div>
      </div>
    </Container>
  );
};

export default Featured;
