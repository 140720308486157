import React, { useState, useEffect } from "react";
import Content from "../components/Content";
import ExperienceButton from "../components/ExperienceButton";
import GraphicDesigner from "../components/GraphicDesigner";
import ProfileImg from "../assets/images/profileimage.jpg";
import Experience from "./About/Experience";
import Biography from "./About/Biography";
import Education from "./About/Education";
import { allImages } from "../assets/index";

function AboutPage(props) {
  const [topic, setTopic] = useState("biography");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeTopic = (topic) => {
    setTopic(topic);
  };

  const expIcons = ["wordpresslogo", "csslogo", "nextjslogo", "typescriptlogo", "htmllogo", "javascriptlogo", "reactlogo", "reactnativelogo", "postmanlogo", "dockerlogo", "dartlogo", "flutterlogo", "firebase", "graphqllogo", "restapilogo", "pythonlogo", "djangologo", "flasklogo", "awslogo", "reduxlogo", "sasslogo", "nodejslogo", "postgreslogo", "mongodblogo", "herokulogo", "vscodelogo", "androidstudio", "mql4logo", "mql5logo", "linuxlogo", "metamask"];
  const expIcons1 = ["photoshop", "illustrator", "indesign", "premiere", "adobexd", "dimensions"];
  const expIcons2 = ["awscodecommitlogo", "awscloudfrontlogo", "awsapilogo", "awsamplifylogo", "awscognito", "awsdynamodblogo", "awsec2logo", "awsiamlogo", "awslambdalogo", "awsrdslogo", "awss3logo"];
  return (
    <div>
      <Content>
        <div className="about-header">
          <div>
            <img className="profile-image min-w-[200px]" src={ProfileImg} alt="profile" />
          </div>
          <div>
            <h1 style={{ fontFamily: "Lobster" }}>About Me</h1>
            <div className="exp-content">
              <ExperienceButton expIcons={expIcons} title="Developer" allImages={allImages} />
              <ExperienceButton expIcons={expIcons1} title="Designer" allImages={allImages} />
              <ExperienceButton expIcons={expIcons2} title="AWS" allImages={allImages} />
            </div>
          </div>
        </div>
      </Content>
      <Content>
        <div style={{ marginTop: 30 }}>
          <button style={{ width: "33%" }} className={topic === "biography" ? "about-btn-active" : "about-btn"} onClick={() => changeTopic("biography")}>
            Biography
          </button>
          <button style={{ width: "33%" }} className={topic === "work" ? "about-btn-active" : "about-btn"} onClick={() => changeTopic("work")}>
            Experience
          </button>
          <button style={{ width: "33%" }} className={topic === "education" ? "about-btn-active" : "about-btn"} onClick={() => changeTopic("education")}>
            Education
          </button>
        </div>
        {topic === "biography" && (
          <>
            <Biography />
          </>
        )}

        {topic === "work" && (
          <>
            <Experience />
          </>
        )}

        {topic === "education" && (
          <>
            <Education />
          </>
        )}
      </Content>
    </div>
  );
}

export default AboutPage;
