import React, { useEffect } from "react";

import Hero from "../components/Hero";
import Experience from "../components/experience/Experience";
import Featured from "../components/Featured";
import Testament from "../components/Testament";
import { experiences } from "../experience";

function HomePage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero title={props.title} subTitle={props.subTitle} text={props.text} />
      <Featured history={props.history} experiences={experiences} />
      <Testament />
      <Experience />
    </div>
  );
}

export default HomePage;
