import React from "react";

function ExperienceButton(props) {
  let experience = [];

  for (let e of props.expIcons) {
    experience.push(<img key={e} src={props.allImages[e]} style={{ width: 30, height: 30, objectFit: "contain", marginLeft: 15, marginBottom: 15 }} />);
  }

  return (
    <div>
      <p>
        <span style={{ color: "#aaa" }}>Technologies used as a </span>
        <b>{props.title}:</b>
      </p>
      <div className="flex flex-wrap">{experience}</div>
    </div>
  );
}

export default ExperienceButton;
