import React from 'react';
import Container from 'react-bootstrap/Container';
import background from '../assets/images/graphic.png';
import { Link } from 'react-router-dom'
import { Heart } from 'lucide-react'

function Hero(props) {

    return(
        <div>
            <Container fluid={true} className="w-full flex items-center justify-center" style={{ backgroundImage:`url(${background})`, height: 900, width: '100%', backgroundPosition: 'center',  backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',  }}>
                <div className="flex items-center justify-center w-full flex-col max-w-[1200px] drop-shadow-2xl">
                        { props.title && <h1 className="hero-title align-center text-center txt-shadow" style={{color: 'white'}}>{props.title}</h1> }
                        { props.subTitle && <h3 className="display-5 font-bold txt-shadow text-center" style={{marginTop: 30, color: 'white', }}>{props.subTitle}</h3> }
                        <div className="mt-3">
                            <Heart size={42} className="fill-red-500 text-red-500 animate-ping"/>
                        </div>
                        <div>
                        <Link to="/about" ><button className="w-[180px] mx-1 text-red-500 text-2xl font-bold bg-black/50 h-[50px] hover:bg-black">About Me</button></Link>
                        <Link to="/contact" ><button className="w-[180px] mx-1 text-red-500 text-2xl font-bold bg-black/50 h-[50px] hover:bg-black">Contact Me</button></Link>

                        </div>
                        
                        

                </div>
            </Container>
        </div>
    );

}


export default Hero;