import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import { experiences } from "../experience";
import ProjectComp from "../components/ProjectComp";

function Projects() {
  const [visible, setVisible] = useState("all");
  const [exp, setExp] = useState(experiences);

  const selectSection = (selected) => {
    setVisible(selected);
  };

  useEffect(() => {
    if (visible === "all") {
      setExp(experiences);
    } else {
      setExp(experiences.filter((a) => a.type === visible));
    }
    window.scrollTo(0, 0);
  }, [visible]);

  const getLength = (type) => {
    return experiences.filter((a) => a.type === type).length;
  };

  let select = [
    {
      id: 10,
      title: "All",
      length: experiences.length,
    },
    {
      id: 1,
      title: "Javascript",
      length: getLength("javascript"),
    },
    {
      id: 2,
      title: "Python",
      length: getLength("python"),
    },
    {
      id: 3,
      title: "Mobile",
      length: getLength("mobile"),
    },
    {
      id: 4,
      title: "Wordpress",
      length: getLength("wordpress"),
    },
    {
      id: 5,
      title: "Graphics",
      length: getLength("graphics"),
    },
    {
      id: 6,
      title: "Backend",
      length: getLength("backend"),
    },
  ];

  return (
    <div>
      <div className="button-header">Select Your Interests</div>
      <div className="button-align">
        {select.map((a, i) => (
          <button key={i} onClick={() => selectSection(a.title.toLowerCase())} className={visible === a.title.toLowerCase() ? "button-style-active" : "button-style"}>
            <span style={{ fontSize: 20, fontWeight: "bold", fontFamily: "Lobster" }}>{a.title}</span>
            <div>
              <span>
                Projects: <b>{a.length}</b>
              </span>
            </div>
          </button>
        ))}
      </div>
      <Container>
        <div>
          <h2 className="project-title">{visible} Projects</h2>
          <hr className="project-divider" />
        </div>
        {makeItems(exp)}
      </Container>
    </div>
  );
}

const makeItems = (items) => {
  return items.map((item, i) => {
    return (
      <div key={i} style={{ width: "100%" }}>
        <ProjectComp display={item} />
        <hr style={{ backgroundColor: "#fefefe", height: 1, width: "100%" }} />
      </div>
    );
  });
};

export default Projects;
