import React from "react";
import { allImages } from "../../assets/index";

const expIcons = ["wordpresslogo", "csslogo", "tailwind", "nextjslogo", "typescriptlogo", "htmllogo", "javascriptlogo", "reactlogo", "reactnativelogo", "postmanlogo", "dockerlogo", "nginx", "dartlogo", "flutterlogo", "firebase", "graphqllogo", "restapilogo", "pythonlogo", "djangologo", "flasklogo", "awslogo", "reduxlogo", "sasslogo", "nodejslogo", "postgreslogo", "mongodblogo", "herokulogo", "vscodelogo", "androidstudio", "mql4logo", "mql5logo", "github", "bitbucket", "metamask", "linuxlogo"];
const expIcons1 = ["photoshop", "illustrator", "indesign", "premiere", "adobexd", "dimensions"];
const expIcons2 = ["awscodecommitlogo", "awscloudfrontlogo", "awsapilogo", "awsamplifylogo", "awscognito", "awsdynamodblogo", "awsec2logo", "awsiamlogo", "awslambdalogo", "awsrdslogo", "awss3logo"];

const webItems = [];
const designItems = [];
const awsItems = [];

for (let image of expIcons) {
  webItems.push(
    <div key={image} style={{ width: 50, height: 50, marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
      <img src={allImages[image]} style={{ width: 50, height: 50, objectFit: "contain" }} />
    </div>
  );
}

for (let image of expIcons2) {
  awsItems.push(
    <div key={image} style={{ width: 60, height: 60, alignItems: "center", marginLeft: 20, marginRight: 20 }}>
      <img src={allImages[image]} style={{ alignItems: "center", width: 50, height: 50, objectFit: "contain" }} />
    </div>
  );
}

for (let image of expIcons1) {
  designItems.push(
    <div key={image} style={{ width: 60, height: 60, alignItems: "center", marginLeft: 20, marginRight: 20 }}>
      <img src={allImages[image]} style={{ alignItems: "center", width: 50, height: 50, objectFit: "contain" }} />
    </div>
  );
}

const Experience = () => {
  return (
    <div className="experience-box">
      <h4 style={{ textAlign: "center", fontSize: 36, marginTop: 50, fontFamily: "Lobster" }}>Technologies Used</h4>
      <div style={{ marginTop: 20 }}>
        <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", color: "#ccc" }}>Web & Mobile Technologies</span>
        <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10, display: "flex", justifyContent: "center", flexWrap: "wrap", maxWidth: 900 }}>{webItems}</div>
      </div>
      <div style={{ marginBottom: 10, marginTop: 0 }}>
        <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", color: "#ccc" }}>AWS Specific</span>
        <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10, display: "flex", justifyContent: "center", flexWrap: "wrap", maxWidth: 900 }}>{awsItems}</div>
      </div>
      <div style={{ marginBottom: 10, marginTop: 30 }}>
        <span style={{ display: "flex", justifyContent: "center", color: "#ccc", fontWeight: "bold" }}>Design Software</span>
        <div style={{ marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center", marginTop: 10, display: "flex", justifyContent: "center", flexWrap: "wrap", maxWidth: 900 }}>{designItems}</div>
      </div>
    </div>
  );
};

export default Experience;
