export const resume = [
  {
    id: 7,
    title: "Fundrate",
    date: "Nov 2022 - Nov 2023",
    desc: ["Create frontend and backend application using nextjs and tailwind.", "Create an autonomous scraper in python using selenium that scrape croudfunding campaings on a regular basis." , "Host the scraper on an EC2 and connect to a pipeline to streamline publications.", "Create a bash script to initiates the python script.", "Download images and videos from the website, store the assets on S3 and create a reference inside a AWS RDS database.", "Host nextjs project on vercel with an development and production environment", "Set up email service using mailgun for the nextjs project and for the python scrape.", "Add failsafe code to continue scrape and notify developer in case of errors."],
  },
  {
    id: 6,
    title: "Salt Media",
    date: "Apr 2021 - Dec 2021",
    desc: ["Set up nginx and ec2 server using strapi, while hosting the react site on the ec2 server on a separate port.", "Connect route 53 to the ec2 server.", "Build reactjs frontend.", "Troubleshoot aws cloudfront issue using aws amplify ", "Work in groups on business internal platform for managing freelancers.", "Use AWS IAM to provide access to specific users."],
  },
  {
    id: 2,
    title: "Upwork Freelancer - Contractor",
    date: "Dec 2016 – Current",
    desc: ["Assisted 18 clients on +30 projects generating +20k.", "Received a client rating of 4.9 / 5 and a success score of 98%.", "Designed and developed Wordpress and Javascript websites.", "Optimized sites for on-page and off-page SEO.", "Created Logos, banners and other assets using Photoshop and Illustrator."],
  },
  {
    id: 1,
    title: "Jammedia, Wordpress Specialist",
    date: "Oct 2016 – Jan 2021",
    desc: ["Built websites using WordPress.", "Customized plugins and themes for clients.", "Used Photoshop and Illustrator to create logos and banners.", "Prepared website proposals to present to new clients.", "Provided technical support to clients.", "Written clean code using HTML, CSS, Javascript and PHP.", "Optimized sites for performance.", "Applied SEO strategies to the website and content.", "Optimized sites for all browsers and mobile devices."],
  },

  {
    id: 3,
    title: "SquadProfile, Full Stack Web Developer",
    date: "Feb 2020 – Jun 2020",
    desc: ["Use ReactJs and NodeJs to build a Web App.", "Use ReactJs and NodeJs to build a Web App.", "Use JS as CSS for dynamic styling.", "Use good coding practice to remove existing bugs.", "Use CI/CD methodology using Github.", "Act as team lead by assisting other developers and participate in weekly meetings."],
  },
  {
    id: 4,
    title: "Lion Media, Full Stack Web Developer",
    date: "Feb 2020 – Jan 2021",
    desc: ["Connected Api to Website to allow users to filter data.", "Connected SSL certificate and improve security measures.", "Provided web design and development expertise to new and existing clients.", "Applied SEO strategies through keyword optimizations, custom metadata and backlinking."],
  },
  {
    id: 5,
    title: "Academie A.I.M.E., Full Stack Web Developer",
    date: "Dec 2019 – Feb 2020",
    desc: ["Worked as part of a team and autonomously.", "Worked with EJS, Firebase, NodeJs and JQuery to implement new features, while fixing existing bugs.", "Connected through FTP to implement live changes to code.", "Deployed applications, and monitored the performance and logs for any anomalies."],
  },
];
