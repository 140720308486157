import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { allImages } from "../assets/index";
import { faEye, faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { ChevronRight } from 'lucide-react'

const ProjectComp = (props) => {
  const { display } = props;
  const [image, setImage] = useState(0);

  const showImage = () => {
    if (image >= display.imgSrc.length - 1) {
      setImage(0);
    } else {
      setImage((prev) => prev + 1);
    }
  };

  return (
    <div className="flex justify-center w-full max-w-[1200px] max-md:flex-col">
      <div>
        <div className="relative">
          {display.imgSrc.length > 1 && (
            <div className="img-btn" onClick={() => showImage()}>
              <FontAwesomeIcon className="button-icon" icon={faArrowAltCircleRight} color="#ff0066" size="2x" />
            </div>
          )}
          <img className="project-img" src={allImages[display?.imgSrc[image]]} />
        </div>
        <div style={{ width: "100%", display: "flex", width: "100%" }}>
          {display?.liveLink && (
            <div style={{ width: "100%" }}>
              <button onClick={() => window.open(display?.liveLink)} className="featured-btn-active" style={{ width: "100%" }}>
                <FontAwesomeIcon className="button-icon" icon={faEye} size="1x" /> Live
              </button>
            </div>
          )}
          {display?.githubLink && (
            <div style={{ width: "100%" }}>
              <button onClick={() => window.open(display?.githubLink)} className="featured-btn-active" style={{ width: "100%" }}>
                <FontAwesomeIcon className="button-icon" icon={faEye} size="1x" /> Github
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="featured-textbox">
        <div className="mt-3">
          <span className="featured-title">{display?.title}</span>
          <br />
          <span className="featured-subTitle">{display?.subTitle}</span>
        </div>
        <div>
          <p className="featured-description">
            {display?.description.map((a, i) => (
              <div key={i} className="flex text-[16px] ">
                <span className="min-w-[20px] mt-1"><ChevronRight size={18} className="text-gray-400" /></span>
                <span>{a}</span>
              </div>
              
            ))}
          </p>
        </div>
        <div>
          <span className="featured-technology">Technologies</span>
          <div className="featured-technology-icon flex ">
            {display?.technologies.map((a, i) => (
              <img key={i} src={allImages[a]} style={{ height: 35, width: 35, marginRight: 10, marginBottom: 10 }} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectComp;
