import React from "react";

const Education = () => {
  return (
    <div>
      <h3 className="bio-title" style={{ textAlign: "center", fontFamily: "Lobster" }}>
        Education
      </h3>
      <hr />
      <div>
        <span className="exp-title">University of Alberta, MA Sport Psychology</span>
        <br />
        <span className="exp-month">August 2016</span>
        <br />
        <span className="exp-subtitle">Edmonton, AB, Canada</span>
        <p className="exp-desc">The thesis focused on how virtual education can improve learning retention and attentiveness. The findings uncovered the current technological limitations of user engagement and challenges of implementing adaptive learning measures.</p>
      </div>
      <div>
        <span className="exp-title">University of Alberta – Bachelor’s Degree in Psychology</span>
        <br />
        <span className="exp-month">Sept 2009 – April 2013</span>
        <p className="exp-desc">Completed the degree with a 3.4 GPA</p>
      </div>

      <div>
        <span className="exp-title">Google Ads Display Certification</span>
        <br />
        <span className="exp-month">February 2020</span>
        <br />
        <span className="exp-subtitle">Verified By Google</span>
        <p className="exp-desc">The Display Advertising exam covers advanced concepts and best practices for creating, managing, measuring, and optimizing Display campaigns.</p>
      </div>
      <div>
        <span className="exp-title">Google Analytics Individual Qualification</span>
        <br />
        <span className="exp-month">January 2020</span>
        <br />
        <span className="exp-subtitle">Verified By Google</span>
        <p className="exp-desc">The Google Analytics Individual Qualification (IQ) is a demonstration of proficiency in Google Analytics that is available to any individual who has passed the Google Analytics IQ exam.</p>
      </div>
      <div>
        <span className="exp-title">FreeCodeCamp - Certified Full Stack Developer </span>
        <br />
        <span className="exp-month">August 2019</span>
        <br />
        <li className="exp-point">Responsive Web Design Certification - Completed</li>
        <li className="exp-point">Javascript Algorythms and Data Structures Certification - Completed Front </li>
        <li className="exp-point">End Libraries Certification - Completed </li>
        <li className="exp-point">Data Visualization Certification - Completed </li>
        <li className="exp-point">Apis and Microservice Certification - Completed</li>
        <li className="exp-point">Information Security and Quality Assurance Certification - Completed</li>
      </div>
    </div>
  );
};

export default Education;
