import React, { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import Content from "../components/Content";
import { allImages } from "../assets/index";

function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="form-align">
        <span>
          <Content>
            <div style={{ position: "relative", marginBottom: 10, marginTop: 10 }}>
              <img src={allImages["contactform"]} alt="contactform" style={{ width: "100%", height: 150, borderTopLeftRadius: 20, borderBottomRightRadius: 20, objectFit: "cover", objectPosition: "bottom" }} />
              <div style={{ position: "absolute", top: 0, alignItems: "center", justifyContent: "center", left: 0, right: 0 }} className="about-title">
                <span style={{ textAlign: "center", color: "#fff", fontSize: 32, fontFamily: "Lobster" }}>Let's Realize Your Project</span>
              </div>
            </div>
            <span>
              Contact me via E-mail: <b>n-k@live.ca</b> or use the form.
            </span>
            <div style={{ marginTop: 10, backgroundColor: "#ff330010", padding: 10, border: "1px solid #ff3300", borderRadius: 5 }}>
              <li style={{ fontSize: 14 }}>Please make sure to be as descriptive as possible to ensure that we are both on the same page.</li>
              <li style={{ fontSize: 14 }}>Feel free to ask for a product template to help create a blueprint of your project.</li>
              <li style={{ fontSize: 14 }}>Let me know if there are any special features your would like that you haven't seen anywhere.</li>
              <li style={{ fontSize: 14 }}>I'd be happy to help with any existing projects.</li>
            </div>
          </Content>
        </span>
        <div className="form-style">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
