
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faGithub
  } from "@fortawesome/free-brands-svg-icons";

  function SocialFollow(){
      return (
          <>
        <a
        href="https://www.facebook.com/noahkanyo"
        className="facebook social"
     >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
     </a>
     <a
        href="https://www.github.com/kanyo33"
        className="github social"
        style={{marginLeft: 10}}
     >
        <FontAwesomeIcon icon={faGithub} size="2x" />
     </a>
     </>
      )
  }

  export default SocialFollow