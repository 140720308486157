import React, { useState } from "react";
import axios from "axios";
import Content from "./Content";

export default () => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    email: "",
    message: "",
  });
  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        email: "",
        message: "",
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: "https://formspree.io/maywwwyy",
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(true, "Thank you, your message has been submitted.");
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };
  return (
    <Content>
      <main>
        <form onSubmit={handleOnSubmit}>
          <div className="form-box">
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <span style={{ color: "#aaa" }}>Email</span>
            </div>
            <input id="email" style={{ width: "100%" }} type="email" name="_replyto" placeholder="Your@email.com" className="form-input" onChange={handleOnChange} required value={inputs.email} />
          </div>
          <div className="form-box">
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <span style={{ color: "#aaa" }}>Message</span>
            </div>
            <div>
              <textarea placeholder="What is your project about?" id="message" name="message" style={{ width: "100%" }} className="form-textarea" onChange={handleOnChange} required value={inputs.message} />
            </div>
          </div>
          <button type="submit" style={{ width: "100%" }} disabled={status.submitting} className="featured-btn-active">
            {!status.submitting ? (!status.submitted ? "Submit" : "Submitted") : "Submitting..."}
          </button>
        </form>
        {status.info.error && <div className="error">Error: {status.info.msg}</div>}
        {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      </main>
    </Content>
  );
};
