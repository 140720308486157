export const allImages = {
  adobexd: require("./images/allImages/adobexd.png"),
  androidstudio: require("./images/allImages/androidstudio.png"),
  bannerResize: require("./images/allImages/bannerResize.jpg"),
  bookcover: require("./images/allImages/bookcover.jpg"),
  bootstraplogo: require("./images/allImages/bootstraplogo.png"),
  capture2: require("./images/allImages/capture2.jpg"),
  bodyweightpro1: require("./images/allImages/bodyweightpro1.jpg"),
  bodyweightpro2: require("./images/allImages/bodyweightpro2.jpg"),
  bodyweightpro3: require("./images/allImages/bodyweightpro3.jpg"),
  bodyweightpro4: require("./images/allImages/bodyweightpro4.jpg"),
  bodyweightpro5: require("./images/allImages/bodyweightpro5.jpg"),
  bodyweightpro6: require("./images/allImages/bodyweightpro6.jpg"),
  bodyweightpro7: require("./images/allImages/bodyweightpro7.jpg"),
  bodyweightpro8: require("./images/allImages/bodyweightpro8.jpg"),
  bodyweightpro9: require("./images/allImages/bodyweightpro9.jpg"),
  bodyweightproweb: require("./images/allImages/bodyweightproweb.jpg"),
  reactinputbutton: require("./images/allImages/react-input-button.png"),
  coro: require("./images/allImages/coro.jpg"),
  corona2: require("./images/allImages/corona2.jpg"),
  corona3: require("./images/allImages/corona3.jpg"),
  corona4: require("./images/allImages/corona4.jpg"),
  corona5: require("./images/allImages/corona5.jpg"),
  corona6: require("./images/allImages/corona6.jpg"),
  corona7: require("./images/allImages/corona7.jpg"),
  corona8: require("./images/allImages/corona8.jpg"),
  corona9: require("./images/allImages/corona9.jpg"),
  corona13: require("./images/allImages/corona13.jpg"),
  corona19: require("./images/allImages/corona19.jpg"),
  css: require("./images/allImages/css.png"),
  csslogo: require("./images/allImages/csslogo.png"),
  dart: require("./images/allImages/dart.png"),
  dartlogo: require("./images/allImages/dartlogo.png"),
  dictionairypython: require("./images/allImages/dictionairypython.jpg"),
  dimensions: require("./images/allImages/dimensions.png"),
  django: require("./images/allImages/django.png"),
  djangologo: require("./images/allImages/djangologo.png"),
  djangologo1: require("./images/allImages/djangologo1.png"),
  djangoreact: require("./images/allImages/djangoreact.jpg"),
  dockerlogo: require("./images/allImages/dockerlogo.png"),
  domcreator: require("./images/allImages/domcreator.jpg"),
  domshortener: require("./images/allImages/domshortener.jpg"),
  editmindlogo2: require("./images/allImages/editmindlogo2.jpg"),
  education1: require("./images/allImages/education1.jpg"),
  elcharro: require("./images/allImages/elcharro.jpg"),
  exercisetracker: require("./images/allImages/exercisetracker.jpg"),
  feature1: require("./images/allImages/feature1.jpg"),
  featurebanner: require("./images/allImages/featurebanner.jpg"),
  featurebannerfinal: require("./images/allImages/featurebannerfinal.jpg"),
  firebaselogo: require("./images/allImages/firebaselogo.png"),
  firebase: require("./images/allImages/firebase.png"),
  fitnessflyer: require("./images/allImages/fitnessflyer.jpg"),
  fitnesslogo: require("./images/allImages/fitnesslogo.jpg"),
  fitvise1: require("./images/allImages/fitvise1.jpg"),
  fitvise2: require("./images/allImages/fitvise2.jpg"),
  fitvise3: require("./images/allImages/fitvise3.jpg"),
  fitvise4: require("./images/allImages/fitvise4.jpg"),
  fitvise5: require("./images/allImages/fitvise5.jpg"),
  fitvise6: require("./images/allImages/fitvise6.jpg"),
  fitvise8: require("./images/allImages/fitvise8.jpg"),
  fitvise10: require("./images/allImages/fitvise10.jpg"),
  fitvise11: require("./images/allImages/fitvise11.jpg"),
  fitvise12: require("./images/allImages/fitvise12.jpg"),
  flask: require("./images/allImages/flask.png"),
  flasklogo: require("./images/allImages/flasklogo.png"),
  flutter: require("./images/allImages/flutter.png"),
  flutterlogo: require("./images/allImages/flutterlogo.png"),
  forexvisor: require("./images/allImages/forexvisor.jpg"),
  freedom: require("./images/allImages/freedom.jpg"),
  fundrate1: require('./images/allImages/fundrate1.png'),
  fundrate2: require('./images/allImages/fundrate2.png'),
  fundrate3: require('./images/allImages/fundrate3.png'),
  gamechangerz1: require("./images/allImages/gamechangerz1.jpg"),
  gamechangerz2: require("./images/allImages/gamechangerz2.jpg"),
  gamechangerz3: require("./images/allImages/gamechangerz3.jpg"),
  gamechangerz4: require("./images/allImages/gamechangerz4.jpg"),
  graphic: require("./images/allImages/graphic.jpg"),
  graphqllogo: require("./images/allImages/graphqllogo.png"),
  heroku: require("./images/allImages/heroku.png"),
  herokulogo: require("./images/allImages/herokulogo.png"),
  home: require("./images/allImages/home.jpg"),
  html: require("./images/allImages/html.png"),
  htmllogo: require("./images/allImages/htmllogo.png"),
  illustrator: require("./images/allImages/illustrator.png"),
  indesign: require("./images/allImages/indesign.png"),
  introapp: require("./images/allImages/introapp.jpg"),
  jamdetroit: require("./images/allImages/jamdetroit.jpg"),
  javascript: require("./images/allImages/javascript.png"),
  javascriptlogo: require("./images/allImages/javascriptlogo.png"),
  laptop: require("./images/allImages/laptop.jpg"),
  linuxlogo: require("./images/allImages/linux.png"),
  logodesign: require("./images/allImages/logodesign.jpg"),
  macomb: require("./images/allImages/macomb.jpg"),
  mappython: require("./images/allImages/mappython.jpg"),
  metamask: require('./images/allImages/metamask.png'),
  mongodb: require("./images/allImages/mongodb.png"),
  mongodblogo: require("./images/allImages/mongodblogo.png"),
  musclemapfitnessapp: require("./images/allImages/musclemapfitnessapp.jpg"),
  nodejs: require("./images/allImages/nodejs.png"),
  nodejslogo: require("./images/allImages/nodejslogo.png"),
  photoshop: require("./images/allImages/photoshop.png"),
  phplogo: require("./images/allImages/phplogo.png"),
  postgresql: require("./images/allImages/postgresql.png"),
  postgreslogo: require("./images/allImages/postgreslogo.png"),
  postmanlogo: require("./images/allImages/postmanlogo.png"),
  premiere: require("./images/allImages/premiere.png"),
  premiumbanner: require("./images/allImages/premiumbanner.jpg"),
  premiumbanner1: require("./images/allImages/premiumbanner1.jpg"),
  premiumbutton: require("./images/allImages/premiumbutton.jpg"),
  producthunt: require("./images/allImages/producthunt.jpg"),
  profileimage: require("./images/allImages/profileimage.jpg"),
  protectparadise: require("./images/allImages/protectparadise.jpg"),
  protectparadise2: require("./images/allImages/protectparadise2.jpg"),
  protectparadise3: require("./images/allImages/protectparadise3.jpg"),
  protectparadise4: require("./images/allImages/protectparadise4.jpg"),
  python: require("./images/allImages/python.png"),
  pythonlogo: require("./images/allImages/pythonlogo.png"),
  randomjoke: require("./images/allImages/randomjoke.jpg"),
  react: require("./images/allImages/react.png"),
  reactcalc: require("./images/allImages/reactcalm.jpg"),
  reactform: require("./images/allImages/reactform.jpg"),
  reactlogo: require("./images/allImages/reactlogo.png"),
  reactnative: require("./images/allImages/reactnative.png"),
  reactnativelogo: require("./images/allImages/reactnativelogo.png"),
  redux: require("./images/allImages/redux.png"),
  reduxlogo: require("./images/allImages/reduxlogo.png"),
  restapilogo: require("./images/allImages/restapilogo.png"),
  restfulapi: require("./images/allImages/restfulapi.png"),
  reviews: require("./images/allImages/reviews.jpg"),
  sasslogo: require("./images/allImages/sasslogo.png"),
  socialmedia: require("./images/allImages/socialmedia.jpg"),
  software: require("./images/allImages/software.jpg"),
  spacex: require("./images/allImages/spacex.jpg"),
  steel: require("./images/allImages/steel.jpg"),
  summaryscreenshots: require("./images/allImages/summaryscreenshots.jpg"),
  tailwind: require("./images/allImages/tailwind.png"),
  todo: require("./images/allImages/todo.jpg"),

  trainingplan: require("./images/allImages/trainingplan.jpg"),
  vault: require("./images/allImages/vault.jpg"),
  vscodelogo: require("./images/allImages/vscodelogo.png"),
  webhires512: require("./images/allImages/webhires512.jpg"),
  wixlogo: require("./images/allImages/wixlogo.png"),
  wordpresslogo: require("./images/allImages/wordpresslogo.png"),
  wunder: require("./images/allImages/wunder.jpg"),
  xylophone: require("./images/allImages/xylophone.jpg"),
  publicgem1: require("./images/allImages/publicgem1.jpg"),
  publicgem2: require("./images/allImages/publicgem2.jpg"),
  publicgem3: require("./images/allImages/publicgem3.jpg"),
  publicgem4: require("./images/allImages/publicgem4.jpg"),
  mediamob1: require("./images/allImages/mediamob1.jpg"),
  mediamob2: require("./images/allImages/mediamob2.jpg"),
  mediamob3: require("./images/allImages/mediamob3.jpg"),
  huntstand: require("./images/allImages/huntstand.jpeg"),
  awsamplifylogo: require("./images/allImages/awsamplifylogo.png"),
  awsapilogo: require("./images/allImages/awsapilogo.png"),
  awscloudfrontlogo: require("./images/allImages/awscloudfrontlogo.png"),
  awscodecommitlogo: require("./images/allImages/awscodecommitlogo.png"),
  awscognito: require("./images/allImages/awscognito.png"),
  awsdynamodblogo: require("./images/allImages/awsdynamodblogo.png"),
  awsec2logo: require("./images/allImages/awsec2logo.png"),
  awsiamlogo: require("./images/allImages/awsiamlogo.png"),
  awslambdalogo: require("./images/allImages/awslambdalogo.png"),
  awslogo: require("./images/allImages/awslogo.png"),
  awsrdslogo: require("./images/allImages/awsrdslogo.png"),
  awss3logo: require("./images/allImages/awss3logo.png"),
  mql4logo: require("./images/allImages/mql4logo.jpeg"),
  mql5logo: require('./images/allImages/mql5logo.png'),
  nextjslogo: require("./images/allImages/nextjslogo.png"),
  typescriptlogo: require("./images/allImages/typescriptlogo.png"),
  contactform: require("./images/allImages/contactform.jpg"),
  djangoportfolio: require("./images/allImages/djangoportfolio.jpg"),
  bitbucket: require("./images/allImages/bitbucket.png"),
  github: require("./images/allImages/github.png"),
  nginx: require("./images/allImages/nxinx.png"),
};
